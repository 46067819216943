<template>
  <v-form ref="form" @submit.prevent="handleSave">
    <v-container class="pa-4 pb-16 pb-md-8">
      <v-row class="mb-8">
        <v-col cols="12" class="text-truncate">
          {{ $trans("to") }}: ({{
            $trans("send_customer_notification_number_info")
          }}: {{ numberOfReceivers }}) {{ receiversToShow }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            v-model="notificationType"
            :items="notificationTypes"
            :label="$trans('notification_type_title')"
            outlined
            hide-details
            :disabled="isLoading"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="
          notificationType === $helpers.customerNotificationType.email ||
          notificationType === $helpers.customerNotificationType.push
        "
      >
        <v-col cols="12">
          <v-text-field
            ref="subject"
            v-model="subject"
            :rules="[rules.required, rules.maxChars(255)]"
            :label="$trans('notification_subject')"
            outlined
            :counter="counterSubjectLength"
            :counter-value="counterValue"
            :disabled="isLoading"
          >
            <template #append-outer>
              <notification-variable-menu
                class="text-field-notification-variable-menu"
                :variables="getVariables"
                @select="
                  insertTextOnCursorPosition($event, 'subject', 'subject')
                "
              />
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row
        v-if="notificationType === $helpers.customerNotificationType.email"
      >
        <v-col cols="12">
          <tip-tap
            ref="textarea"
            v-model="content"
            :placeholder="$trans('notification_content')"
            :disabled="isLoading"
            @input="content = $event"
          />
        </v-col>
        <v-col>
          <notification-variable-menu
            show-text
            :variables="getVariables"
            @select="insertTextToHTMLTextarea($event, 'textarea')"
          />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col
          v-if="
            getNonGsmCharacters(content) &&
            getNonGsmCharacters(content).length > 0
          "
        >
          {{ $trans("notification_contains_special_characters") }}:
          {{ getNonGsmCharacters(content).join(", ") }}
        </v-col>
        <v-col cols="12" class="pb-0 mb-0">
          <v-textarea
            ref="textarea"
            v-model="content"
            :placeholder="$trans('notification_content')"
            :disabled="isLoading"
            height="150"
            outlined
            :counter="counterContentLength"
            :counter-value="counterValue"
            :hint="getTextareaHint"
            persistent-hint
          />
        </v-col>
        <v-col cols="12" class="mt-0 pt-0">
          <notification-variable-menu
            show-text
            :variables="getVariables"
            @select="insertTextOnCursorPosition($event, 'textarea', 'content')"
          />
        </v-col>
        <v-col v-if="getTextareaHint">
          <small>*{{ $trans("sms_number_of_messages_info") }}</small>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import {
  email,
  maxChars,
  required,
} from "@/lib/calendesk-js-library/forms/validators";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";
import NotificationVariableMenu from "@/views/dashboard/pages/Notifications/NotificationTemplateEditor/components/NotificationVariableMenu";
import { trans } from "@/lib/calendesk-js-library/prototypes/trans";
import {
  insertTextOnCursorPosition,
  insertTextToHTMLTextarea,
  pushEvent,
} from "@/lib/calendesk-js-library/tools/helpers";
import TipTap from "@/lib/calendesk-js-library/components/TipTap";
import smsActions from "@/calendesk/mixins/smsActions";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "SendCustomerNotificationDialog",
  components: { TipTap, NotificationVariableMenu },
  mixins: [sharedActions, smsActions],
  data() {
    return {
      isLoading: false,
      subject: null,
      content: null,
      isValid: false,
      notificationType: this.$helpers.customerNotificationType.email,
      customerType: this.$helpers.customerNotificationCustomerType.user,
      notificationTypes: [],
      rules: {
        required,
        maxChars,
        email,
      },
    };
  },
  computed: {
    isTypeSMS() {
      return (
        this.notificationType === this.$helpers.customerNotificationType.sms
      );
    },
    isTypePush() {
      return (
        this.notificationType === this.$helpers.customerNotificationType.push
      );
    },
    isTypeEmail() {
      return (
        this.notificationType === this.$helpers.customerNotificationType.email
      );
    },
    counterContentLength() {
      if (this.isTypeSMS) {
        return 160;
      }
      if (this.isTypePush) {
        return 240;
      }

      return true;
    },
    counterSubjectLength() {
      if (this.isTypeEmail) {
        return 255;
      }
      if (this.isTypePush) {
        return 65;
      }

      return true;
    },
    getTextareaHint() {
      if (this.isTypeSMS && this.content && this.content.length > 0) {
        return this.getSMSHint(this.content);
      }

      return null;
    },
    selectedIds() {
      if (this.getDialog) {
        return this.getDialog.data.selectedUsers.map((user) => user.id);
      }

      return [];
    },
    usersWithEmail() {
      if (
        this.customerType ===
          this.$helpers.customerNotificationCustomerType.user ||
        this.customerType ===
          this.$helpers.customerNotificationCustomerType.newsletter
      ) {
        return this.getDialog.data.selectedUsers.filter((user) => !!user.email);
      } else if (
        this.customerType ===
        this.$helpers.customerNotificationCustomerType.employee
      ) {
        return this.getDialog.data.selectedUsers.filter(
          (employee) => !!employee.user.email
        );
      }

      return [];
    },
    usersWithPhone() {
      if (
        this.customerType ===
        this.$helpers.customerNotificationCustomerType.user
      ) {
        return this.getDialog.data.selectedUsers.filter(
          (user) => user.default_phone && user.default_phone.e164
        );
      } else if (
        this.customerType ===
        this.$helpers.customerNotificationCustomerType.employee
      ) {
        return this.getDialog.data.selectedUsers.filter(
          (employee) =>
            employee.user.default_phone && employee.user.default_phone.e164
        );
      }

      return [];
    },
    usersWithPush() {
      if (
        this.customerType ===
        this.$helpers.customerNotificationCustomerType.user
      ) {
        return this.getDialog.data.selectedUsers.filter(
          (user) => user.accepts_push
        );
      } else if (
        this.customerType ===
        this.$helpers.customerNotificationCustomerType.employee
      ) {
        return this.getDialog.data.selectedUsers.filter(
          (employee) => employee.accepts_push
        );
      }

      return [];
    },
    emails() {
      if (
        this.customerType ===
          this.$helpers.customerNotificationCustomerType.user ||
        this.customerType ===
          this.$helpers.customerNotificationCustomerType.newsletter
      ) {
        return this.usersWithEmail
          ? this.usersWithEmail.map((user) => user.email).join(", ")
          : [];
      } else if (
        this.customerType ===
        this.$helpers.customerNotificationCustomerType.employee
      ) {
        return this.usersWithEmail
          ? this.usersWithEmail
              .map((employee) => employee.user.email)
              .join(", ")
          : [];
      }

      return [];
    },
    phones() {
      if (
        this.customerType ===
        this.$helpers.customerNotificationCustomerType.user
      ) {
        return this.usersWithPhone
          ? this.usersWithPhone
              .map((user) => user.default_phone.e164)
              .join(", ")
          : [];
      } else if (
        this.customerType ===
        this.$helpers.customerNotificationCustomerType.employee
      ) {
        return this.usersWithPhone
          ? this.usersWithPhone
              .map((employee) => employee.user.default_phone.e164)
              .join(", ")
          : [];
      }

      return [];
    },
    acceptsPush() {
      if (
        this.customerType ===
        this.$helpers.customerNotificationCustomerType.user
      ) {
        return this.usersWithPush
          ? this.usersWithPush.map((user) => user.id).join(", ")
          : [];
      } else if (
        this.customerType ===
        this.$helpers.customerNotificationCustomerType.employee
      ) {
        return this.usersWithPush
          ? this.usersWithPush.map((employee) => employee.id).join(", ")
          : [];
      }

      return [];
    },
    numberOfReceivers() {
      if (
        this.notificationType === this.$helpers.customerNotificationType.email
      ) {
        return this.usersWithEmail.length;
      }
      if (
        this.notificationType === this.$helpers.customerNotificationType.sms
      ) {
        return this.usersWithPhone.length;
      }

      return this.usersWithPush.length;
    },
    receiversToShow() {
      if (
        this.notificationType === this.$helpers.customerNotificationType.email
      ) {
        return this.emails;
      }
      if (
        this.notificationType === this.$helpers.customerNotificationType.sms
      ) {
        return this.phones;
      }

      return this.acceptsPush;
    },
    receiversToSend() {
      if (
        this.notificationType === this.$helpers.customerNotificationType.email
      ) {
        return this.usersWithEmail.map((record) => record.id);
      }
      if (
        this.notificationType === this.$helpers.customerNotificationType.sms
      ) {
        return this.usersWithPhone.map((record) => record.id);
      }

      return this.usersWithPush.map((record) => record.id);
    },
    getVariables() {
      if (
        this.customerType ===
          this.$helpers.customerNotificationCustomerType.user ||
        this.customerType ===
          this.$helpers.customerNotificationCustomerType.employee
      ) {
        return [
          { text: trans("variable_customer_id"), value: "CUSTOMER_ID" },
          {
            text: trans("variable_customer_full_name"),
            value: "CUSTOMER_FULL_NAME",
          },
          { text: trans("variable_customer_name"), value: "CUSTOMER_NAME" },
          {
            text: trans("variable_customer_surname"),
            value: "CUSTOMER_SURNAME",
          },
          { text: trans("variable_customer_email"), value: "CUSTOMER_EMAIL" },
          { text: trans("variable_customer_phone"), value: "CUSTOMER_PHONE" },
          { text: trans("variable_company_name"), value: "COMPANY_NAME" },
          { text: trans("variable_company_phone"), value: "COMPANY_PHONE" },
          { text: trans("variable_company_address"), value: "COMPANY_ADDRESS" },
        ];
      }
      if (
        this.customerType ===
        this.$helpers.customerNotificationCustomerType.newsletter
      ) {
        return [
          {
            text: trans("variable_newsletter_email"),
            value: "NEWSLETTER_EMAIL",
          },
          { text: trans("variable_company_name"), value: "COMPANY_NAME" },
          { text: trans("variable_company_phone"), value: "COMPANY_PHONE" },
          { text: trans("variable_company_address"), value: "COMPANY_ADDRESS" },
          { text: trans("variable_unsubscribe_url"), value: "UNSUBSCRIBE_URL" },
        ];
      }

      return [];
    },
  },
  watch: {
    numberOfReceivers() {
      this.$emit("ctaButtonDisabled", this.numberOfReceivers === 0);
    },
  },
  created() {
    this.customerType = this.getDialog.data.customerType;

    this.notificationTypes.push({
      text: this.$trans("notification_type_1"),
      value: this.$helpers.customerNotificationType.email,
    });

    if (
      this.customerType ===
        this.$helpers.customerNotificationCustomerType.user ||
      this.customerType ===
        this.$helpers.customerNotificationCustomerType.employee
    ) {
      this.notificationTypes.push(
        {
          text: this.$trans("notification_type_2"),
          value: this.$helpers.customerNotificationType.sms,
        },
        {
          text: this.$trans("notification_type_3"),
          value: this.$helpers.customerNotificationType.push,
        }
      );
    }
  },
  methods: {
    ...mapActions({
      createCustomerNotification: "customerNotification/create",
    }),
    handleSave() {
      if (this.$refs.form.validate() && this.content) {
        this.openConfirmDialog({
          type: sharedConfirmDialogTypes.COMMON,
          title: this.$trans("send_customer_notification_confirm_title"),
          warning: this.$trans("send_customer_notification_confirm_info", {
            type: this.$trans(`notification_type_${this.notificationType}`),
            amount: this.numberOfReceivers,
          }),
          importantInformation: this.$trans("no_way_to_undo_action"),
          confirmationText: this.$trans("send"),
          confirmAction: () => {
            this.closeConfirmDialog();
            this.clearCache("customerNotification/fetchAll");
            this.send();
          },
        });
      } else {
        errorNotification("form_is_invalid", null, false);
      }
    },
    async send() {
      pushEvent("sendCustomerNotification");

      this.setCommonDialogLoader(true);
      this.isLoading = true;
      this.$emit("ctaButtonLoading", true);
      try {
        await this.createCustomerNotification({
          customer_type: this.customerType,
          notification_type: this.notificationType,
          customer_ids: this.receiversToSend,
          subject: this.subject,
          content: this.content,
        });
        successNotification("notifications_sent");
        this.$emit("close");
      } catch (error) {
        errorNotification(null, error);
      } finally {
        this.setCommonDialogLoader(false);
        this.isLoading = false;
        this.$emit("ctaButtonLoading", false);
      }
    },
    insertTextOnCursorPosition(text, refName, localVariableName) {
      insertTextOnCursorPosition(this, text, refName, localVariableName);
    },
    insertTextToHTMLTextarea(text, refName) {
      insertTextToHTMLTextarea(this, text, refName);
    },
    counterValue(value) {
      return this.$options.filters.tenantVariable(value).length;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-field-notification-variable-menu {
  margin-top: -10px !important;
}
</style>
